import React, { useState, useEffect } from "react";
import SidePanel from "./SidePanel";
import { Rating } from "primereact/rating";
import PhoneIcon from "../assets/images/icons8-phone-50.png";
import side_img from "../assets/images/side_img_1.png";
import side_img2 from "../assets/images/side_img_2.png";
import side_img3 from "../assets/images/side_img_3.png";
import "../assets/css/slidePanel/slidePanel.css";

const sidePanels = [
  {
    title: (
      <>
        à partir de <br />
        <strong className="flex text-5xl mt-3">19.90€HT</strong>
      </>
    ),
    image: side_img,
    background: '#DCE6FF',
    content: [
      "Appels inclus vers Fixes et Mobiles en France et vers l’international*",
      "Standard Téléphonique Virtuel avec toutes les fonctionnalités",
      "Application mobile et Softphone pour chaque utilisateur",
    ],
  },
  {
    title: (
      <>
        <strong style={{ fontSize: '30px' }}>Intégrez votre CRM en 1 clic*</strong>
      </>),
    image: side_img2,
    background: '#FFF1DC',
    subtitle: "inclus dans le Forfait Pro",
    content: [],
  },
  {
    title: (
      <>
        <strong className="flex" style={{ fontSize: '30px'}}>Appels illimités +70 destinations internationales</strong>
      </>
    ),
    background: '#FFFADC',
    image: side_img3,
    subtitle: "inclus dans le Forfait Pro",
    content: [],
  },
];

export default function SlidePanel() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [currentBackground, setCurrentBackground] = useState(sidePanels[0].background);
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleIndicatorClick = (slide) => {
    setCurrentSlide(slide);
  };

  useEffect(() => {
    setCurrentBackground(sidePanels[currentSlide - 1].background);
  }, [currentSlide]);


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide % 3) + 1);
    }, 1000000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const data = await response.json();
        const countryCode = data.country;
        if (countryCode === "BE") {
          setPhoneNumber("01 87 200 200");
        } else if (countryCode === "FR") {
          setPhoneNumber("01 87 200 200");
        } else {
          // Default phone number if country is neither Belgium nor France
          setPhoneNumber("01 87 200 200");
        }
      } catch (error) {
        console.error("Error fetching phone number:", error);
        // Default phone number in case of an error
        setPhoneNumber("01 87 200 200");
      }
    };

    fetchPhoneNumber();
  }, []);


  return (
    <aside style={{ backgroundColor: currentBackground }} className="aside-container">
      {sidePanels.map((panel, index) => (
        <SidePanel
          visibility={currentSlide === ++index}
          key={index}
          title={panel.title}
          image={panel.image}
          subtitle={panel?.subtitle}
          content={panel.content}
          background={panel.background}
        />
      ))}

      <div className="aside-avis">
        <strong>Des milliers d’entreprises satisfaites: </strong>
        <Rating
          unstyled
          value={5}
          readOnly
          cancel={false}
          style={{
            gap: 3,
            color: "#16c599",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <strong
          style={{
            textDecoration: "underline",
          }}
        >
          4.9/5 (493 avis)
        </strong>
      </div>
      <div className="aside-nous-appel">
        <span>Besoin d’aide ? Appelez-nous au </span>
        <strong
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <img src={PhoneIcon} width={"18px"} height={"auto"} alt="phone" />
          {phoneNumber}
        </strong>
      </div>

      <div className="aside-panel-control">
        <div
          className={`indicator ${currentSlide === 1 ? "active" : ""}`}
          onClick={() => handleIndicatorClick(1)}
        />
        <div
          className={`indicator ${currentSlide === 2 ? "active" : ""}`}
          onClick={() => handleIndicatorClick(2)}
        />
        <div
          className={`indicator ${currentSlide === 3 ? "active" : ""}`}
          onClick={() => handleIndicatorClick(3)}
        />
      </div>
    </aside>
  );
}